<template>
    <date-picker :class="class_name" value-type="YYYY-MM-DD HH:mm:ss" v-model="$parent.form[v_model]"
                 format="DD-MMM-YYYY hh:mm A" type="datetime" placeholder="Select datetime"/>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name      : "DateTimePicker",
    components: {DatePicker},
    props     : {
        class_name: {default: ''},
        title     : {
            type   : String,
            default: "Time"
        },
        v_model   : {
            type   : String,
            default: "date"
        },
    },
}
</script>
